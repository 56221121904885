<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <h1 class="text--secondary">Create a QR Code</h1>
        <p>
          Copy any of your URL links above and use them to create your own QR
          code for promotion.
        </p>
      </v-col>
    </v-row>
    <v-btn
      color="primary"
      elevation="0"
      rounded
      class="ma-0"
      href="https://www.qrcode-monkey.com/"
      target="_blank"
    >
      Create QR Code Now
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "GenerateQRCode",
};
</script>
