<template>
  <v-container fluid tag="section" class="pt-0">
    <v-card elevation="0" color="transparent" class="px-5 py-3">
      <GeneratePromotionUrls
        :brand="brand"
        :promote-products="has_dining_service || has_retail_service"
      >
        <template #brand-select v-if="shop.brands && shop.brands.length > 1">
          <v-select
            class="white br-8 px-5 pt-7 pb-5"
            label="Select your brand"
            :value="brand"
            :items="shop.brands.map((b) => ({ ...b, header: null }))"
            item-text="name"
            item-value="id"
            return-object
            @change="(brand_id) => set_brand(brand_id)"
            hide-details
          />
        </template>
      </GeneratePromotionUrls>
      <BookATableIframe
        v-if="
          $vuetify.breakpoint.mdAndUp &&
          table_booking &&
          table_booking.is_active
        "
      />
      <GenerateQRCode class="mb-6" />
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import GeneratePromotionUrls from "@/views/dashboard/components/advertising/GeneratePromotionUrls";
import GenerateQRCode from "@/views/dashboard/components/advertising/GenerateQRCode";
import BookATableIframe from "@/views/dashboard/components/advertising/BookATableIframe";

export default {
  name: "Advertising",
  components: {
    GeneratePromotionUrls,
    GenerateQRCode,
    BookATableIframe,
  },
  computed: {
    ...mapState("ShopStore", ["shop"]),
    ...mapState("BrandStore", ["brand"]),
    ...mapGetters("AdminStore", ["has_retail_service", "has_dining_service"]),
    ...mapGetters("ShopStore", ["table_booking"]),
  },
  methods: {
    ...mapActions("BrandStore", ["set_brand"]),
  },
};
</script>
