<template>
  <v-text-field
    type="text"
    readonly
    :label="label"
    :value="value"
    :append-outer-icon="'$copy'"
    hide-details
    :class="input_class"
  >
    <template #append-outer>
      <v-tooltip bottom content-class="custom-tooltip">
        <template v-slot:activator="{ on }">
          <v-icon
            class="cursor-pointer"
            v-on="on"
            v-clipboard="() => value"
            v-text="'$copy'"
          />
        </template>
        <span>Copy to clipboard</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import Vue from "vue";
import Clipboard from "v-clipboard";

Vue.use(Clipboard);

export default {
  name: "TextFieldClipboard",
  props: {
    value: {
      type: String,
      required: true,
    },
    prepend_icon: {
      type: String,
    },
    label: {
      type: String,
    },
    input_class: {
      type: String,
      default: "",
    },
  },
};
</script>
