<template>
  <div>
    <h1 class="text--secondary">Generate Your URL</h1>
    <p>
      Direct customers to you by using your URL on all of your social media
      channels & on your website.
    </p>
    <div
      class="shop-url-grid mb-6"
      :class="{ '--with-brand': shop.brands && shop.brands.length > 1 }"
    >
      <slot name="brand-select"></slot>
      <TextFieldClipboard
        input_class="white br-8 px-5 pt-7 pb-5"
        label="Your URL"
        :value="shop_url"
      />
    </div>
    <template v-if="promoteProducts && brand">
      <h1 class="text--secondary">Promote Your Category</h1>
      <p>
        Generate a URL of your chosen category and direct your customers there.
      </p>
      <div class="promote-products-grid mb-6">
        <v-autocomplete
          class="white br-8 px-5 pt-7 pb-5 mb-0"
          label="Category"
          v-model="promotion_category"
          placeholder="Select Category"
          :items="active_categories_for_service_id__options(promotion_service)"
          item-text="text"
          item-value="value"
          hide-details
          no-data-text="No Categories Found"
          clearable
        ></v-autocomplete>

        <v-autocomplete
          label="Service"
          v-model="promotion_service"
          class="white br-8 px-5 pt-7 pb-5 mb-0"
          placeholder="Select Service"
          hide-details
          :items="active_services_for_category_id__options(promotion_category)"
          item-text="text"
          item-value="value"
          no-data-text="No Services Active"
          clearable
        ></v-autocomplete>

        <TextFieldClipboard
          input_class="white br-8 px-5 pt-7 pb-5 mb-0"
          :value="promotion_url"
          label="Your URL"
        />
      </div>
      <h1 class="text--secondary">Showcase Your Products</h1>
      <p>
        Generate an iFrame code of your chosen category & service above and
        embed it into your existing website.
      </p>

      <ProductsIframe
        :shop_id="shop_id"
        :category_id="promotion_category"
        :shop_url="promotion_url"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import TextFieldClipboard from "@/components/TextFieldClipboard";
import ProductsIframe from "@/views/dashboard/components/advertising/ProductsIframe";

export default {
  name: "GeneratePromotionUrls",
  components: {
    TextFieldClipboard,
    ProductsIframe,
  },
  props: {
    promoteProducts: { type: Boolean, default: false },
  },
  data() {
    return {
      promotion_category: null,
      promotion_service: null,
      brand_id: null,
    };
  },
  computed: {
    ...mapState("ShopStore", ["shop"]),
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("BrandStore", ["brand"]),
    ...mapGetters("AdminStore", [
      "shop_active_brand_ids",
      "active_categories_for_service_id__options",
      "active_services_for_category_id__options",
    ]),
    ...mapGetters("BrandStore", ["brand_url", "brand_type"]),
    shop_url() {
      if (!this.brand_url) return "";
      let url = this.brand_url;
      url = url.slice(0, -1); // Remove last slash

      if (this.brand_type === "single_shop") return url;
      return `${url}/#/shop/${this.shop_id}`;
    },
    promotion_base_url() {
      if (this.brand_type === "single_shop") return this.shop_url + "/#";
      return this.shop_url;
    },
    promotion_url() {
      let query_params = [];
      let promotion_url = [this.promotion_base_url, "products"].join("/");

      if (this.promotion_category) {
        query_params.push(["category", this.promotion_category].join("="));
      }
      if (this.promotion_service) {
        query_params.push(["service_type", this.promotion_service].join("="));
      }
      if (query_params.length) {
        query_params = query_params.join("&");
        promotion_url = [promotion_url, query_params].join("?");
      }
      return promotion_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.promote-products-grid,
.shop-url-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 0.96fr 0.96fr 4fr;
  }
}
.shop-url-grid {
  grid-template-columns: 1fr;

  &.--with-brand {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      grid-template-columns: 1fr 2fr;
    }
  }
}
</style>
