<template>
  <div class="mb-6">
    <div class="d-inline-block">
      <v-switch
        v-model="active"
        class="mt-0"
        color="primary"
        :disabled="!category_id"
        hide-details
        ><template v-slot:label>
          <span class="">Show HTML generator</span></template
        ></v-switch
      >
    </div>
    <v-row v-if="active && category_id">
      <v-col cols="12" md="3">
        <p>Frame Height</p>
        <v-slider
          color="accent"
          v-model="height"
          min="15"
          max="90"
          prepend-icon="mdi-image-size-select-large"
        ></v-slider>
      </v-col>
      <v-col cols="12" md="9">
        <div
          class="iframe-code"
          v-clipboard="() => iframe_code"
          @click="feedback_copied"
        >
          <pre style="white-space: pre-wrap">{{ iframe_code }}</pre>
          <div class="mask">
            <p class="fade-in" v-if="copied">
              <v-icon class="mr-4" color="white" large>mdi-check</v-icon
              ><span class="mt-3">Copied</span>
            </p>
            <p class="fade-in" v-else>
              Click to copy this code snippet <br />
              Embed it on your website to showcase products as, shown in preview
              below
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text class="primary display-1 white--text font-weight-bold"
            >Preview</v-card-text
          >
          <v-card-text> </v-card-text>
          <iframe
            style="width: 100%; border-width: 0px"
            :height="frame_height + 'px'"
            :key="frame_src"
            :src="frame_src"
            title="Order Platform - Iframe"
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import Clipboard from "v-clipboard";

import { mapState } from "vuex";

Vue.use(Clipboard);

export default {
  name: "ProductsIframe",
  data() {
    return {
      active: false,
      height: 15,
      copied: false,
    };
  },
  props: {
    service_type: { type: String },
    shop_id: { type: String },
    shop_url: { type: String },
    category_id: { type: String },
  },
  computed: {
    ...mapState(["iframe_server_url"]),
    frame_src() {
      return `${this.iframe_server_url}/products?shop_id=${
        this.shop_id
      }&category_id=${this.category_id}&shop_url=${encodeURIComponent(
        "https://" + this.shop_url
      )}`;
    },
    frame_height() {
      return this.height * 10;
    },
    iframe_code() {
      return (
        "<iframe\n" +
        `  style="width: 100%; border-width: 0px; height: ${this.frame_height}px;"\n` +
        `  src="${this.frame_src}"\n` +
        '  title="Order Platform - Iframe"\n' +
        "></iframe>"
      );
    },
  },
  methods: {
    feedback_copied() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
  },
};
</script>
